<template>
  <div class="archives">
    <div class="archives-con posR">
      <div class="border-top">
        <img
          src="https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/16e06acf-d2e5-4a13-9f24-a5e9f6171189.png"
        >
      </div>
      <div v-if="info.company_registe_info.entBasicInfo" class="border-center">
        <div class="center-con">
          <div class="tC posR title">
            工业互联网企业节点档案
            <span class="left" :class="change ?'wap_left': ''" />
            <span class="right" :class="change ?'wap_right': ''" />
          </div>
          <div class="logo">
            <img src="https://assets-service.obs.cn-south-1.myhuaweicloud.com/production/mp_4721e780-e588-11ec-944e-fd3b7f20b5b2/ac7590dc-e764-4463-b2cf-1a8716109b8c.png">
          </div>
          <div class="tC c006aa9 f24">中国工业互联网标识解析服务中心</div>
          <div class="tC c006aa9 f26 fwb mt30">
            {{ info.company_registe_info.entBasicInfo.orgName }}
          </div>
          <div class="gongye-info mt30">
            <p>
              <b>公司名称：</b>
              <span>{{ info.company_registe_info.entBasicInfo.orgName }}</span>
            </p>
            <p>
              <b>企业节点：</b>
              <span>{{ info.company_node }}</span>
            </p>
            <p>
              <b>注册时间：</b>
              <span>{{ registerTime() }}</span>
            </p>
            <p>
              <b>已加入工业互联网：</b>
              <span>{{ userTime() }}天</span>
            </p>
            <p>
              <b>年审有效期：</b>
              <span>暂无</span>
            </p>
            <p>
              <b>注册服务机构：</b>
              <span>工业互联网标识解析国家顶级节点--北京</span>
            </p>
          </div>
          <div class="company-info mt30">
            <div class="company-head">
              <span><img
                src="https://gongye.rntd.cn/zhengshu/images/company.png"
              ></span>企业信息
            </div>
            <div class="flex">
              <div class="c-label fwb">中文名称：</div>
              <div>
                {{ info.company_registe_info.entBasicInfo.orgName }}
              </div>
            </div>
            <div class="flex">
              <div class="c-label fwb">官方网站：</div>
              <div>
                {{ info.company_registe_info.entBasicInfo.website || "暂无" }}
              </div>
            </div>
            <div class="flex">
              <div class="c-label fwb">所属行业：</div>
              <div>
                {{
                  info.company_registe_info.entBasicInfo.industryCategory || ""
                }}
              </div>
            </div>
            <div class="flex">
              <div class="c-label fwb">中文地址：</div>
              <div>
                {{ info.company_registe_info.entBasicInfo.orgAddr || "" }}
              </div>
            </div>
            <div>
              <b class="fwb">{{ info.company_registe_info.entBasicInfo.orgCrtType }}：</b>
              <span>{{
                info.company_registe_info.entBasicInfo.orgCrtCode || ""
              }}</span>
            </div>
            <div class="flex">
              <div class="c-label fwb">注册资本：</div>
              <div>
                {{
                  info.company_registe_info.entBasicInfo.regCapital || "0.00"
                }}(万人民币)
              </div>
            </div>
            <div>
              <b class="fwb">法定代表人：</b>
              <span>{{
                info.company_registe_info.entBasicInfo.legalName
              }}</span>
            </div>
            <div class="flex">
              <div class="c-label fwb">经营范围：</div>
              <div>
                {{ info.company_registe_info.entBasicInfo.industrySpecific }}
              </div>
            </div>
            <div v-if="official.length > 0 || shop.length > 0" class="flex">
              <div class="c-label fwb">赋码站点：</div>
            </div>
            <div v-if="official.length > 0" class="flex">
              <div class="c-label">企业官网：</div>
              <div>
                <div v-for="item in official" :key="item.dapp_id">
                  <a :href="'http://' + item.dapp_domain" target="_blank">{{
                    item.dapp_domain
                  }}</a>
                  {{ "（" + item.dapp_name + "）" }}
                </div>
              </div>
            </div>
            <div v-if="shop.length > 0" class="flex">
              <div class="c-label">网上商城：</div>
              <div>
                <div v-for="item in shop" :key="item.dapp_id">
                  <a v-if="item.dapp_port_type == 1" :href="'http://' + item.dapp_domain + '/mall/'" target="_blank">{{
                    item.dapp_domain
                  }}</a>
                  <a v-else :href="'http://' + item.dapp_domain" target="_blank">{{
                    item.dapp_domain
                  }}</a>
                  {{ "（" + item.dapp_name + "）" }}
                </div>
              </div>
            </div>
          </div>
          <div class="link-box mt30">
            <div class="flex">
              <div class="link-ul link-left">
                <h1>数据验证源</h1>
                <ul>
                  <li>
                    <a @click="idfileUrl" target="_blank">企业工业互联网入网许可证</a>
                  </li>
                  <li>
                    <a @click="getUrl" target="_blank">国家工业互联网标识解析</a>
                  </li>
                  <li>
                    <a href="https://www.cdiisp.com/" target="_blank">成都市工业互联网公共服务平台</a>
                  </li>
                  <li>
                    <a @click="websiteManagement" target="_blank">工业互联网标识工网通服务中心</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="logo-box tC mt20">
            <div>
              <img src="../images/node_white.png">
            </div>
            <div>
              <img
                class="small-icon"
                src="../images/mini-logo.png"
              >
            </div>
            <div>
              <img src="https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_c433ed20-eb93-11ec-b8b1-33d46d2ddef5/b36023c9-9441-485c-bf0c-216cd3db7a6a.png">
            </div>
          </div>
        </div>
      </div>
      <div class="border-top">
        <img src="https://gongye.rntd.cn/zhengshu/images/border-bottom.png">
      </div>
    </div>
  </div>
</template>

<script>
import { companyApi, webSiteApi } from "../services/api";
import { formatDate } from "@/utils/time";
import config from "@/config/config";
export default {
  data() {
    return {
      info: {
        company_registe_info: {
          entBasicInfo: {}
        }
      },
      official: [],
      shop: [],
      change: false
    };
  },
  created() {
    document.title = "数字档案";
    if (window.screen.availWidth > 1200) {
      this.change = false;
    } else {
      this.change = true;
    }
  },
  mounted() {
    this.getInfo();
    let _resizeWindow = function() {
      let $html = document.getElementsByTagName("html")[0];
      let offsetWidth = document.body.clientWidth;
      if (!offsetWidth) return;
      console.log(offsetWidth);
      if (window.screen.availWidth > 1200) {
        document.documentElement.style.fontSize = `100px`;
      } else {
        document.documentElement.style.fontSize = `${(16 / 375) * offsetWidth}px`;
      }
      document.body.style.minWidth = "auto";
    };
    _resizeWindow();
  },
  methods: {
    async getData(mpkey) {
      console.log("mpkey", mpkey);
      let { code, data } = await webSiteApi.getAll({ name_is_not_empty: 1, dapp_mpkey: mpkey });
      if (code == 200 && data) {
        this.official = data.official;
        this.shop = data.shop;
      }
    },
    async getInfo() {
      await companyApi.getNodeInfo({
        node: this.$route.query.nodeId
      }).then(res => {
        this.info = res.data;
        this.getData(res.data.company_mpkey);
      });
    },
    registerTime() {
      return formatDate(this.info.company_registe_time, "yyyy年MM月dd日");
    },
    userTime() {
      return this.getDays(this.info.company_registe_time, new Date());
    },
    getDays(date1, date2) {
      date1 = formatDate(date1, "yyyy-MM-dd");
      date2 = formatDate(date2, "yyyy-MM-dd");
      let date1Str = date1.split("-"); // 将日期字符串分隔为数组,数组元素分别为年.月.日
      // 根据年 . 月 . 日的值创建Date对象
      let date1Obj = new Date(date1Str[0], date1Str[1] - 1, date1Str[2]);
      let date2Str = date2.split("-");
      let date2Obj = new Date(date2Str[0], date2Str[1] - 1, date2Str[2]);
      let t1 = date1Obj.getTime();
      let t2 = date2Obj.getTime();
      let dateTime = 1000 * 60 * 60 * 24; // 每一天的毫秒数
      let minusDays = Math.floor((t2 - t1) / dateTime); // 计算出两个日期的天数差
      let days = Math.abs(minusDays); // 取绝对值
      return days;
    },
    getUrl() {
      window.open(`https://idfactory.cn/whois/ui/searchResult?identity=${this.info.company_node}`);
    },
    idfileUrl() {
      window.open(`${config.webSiteHttp}certificate?nodeId=${this.info.company_node}`);
    },
    websiteManagement() {
      window.open(config.webSiteHttp);
    },
    gotoPage(href) {
      if (!href) {
        this.$message({
          message: "无可用连接",
          type: "warning"
        });
        return false;
      }
      window.open(href || "");
    }
  }
};
</script>

<style lang="less" scoped>

html,
body {
  padding: 0;
  margin: 0;
  /* width: 1200px; */
  width: 100%;
}
.archives {
  box-sizing: border-box;
  background: #fdfcfa;
  max-width: 1200px;
  margin: 0 auto;
}

.archives-con {
  overflow: hidden;
  /* width: 1200px; */
  width: 100%;
}

.posR {
  position: relative;
}

.border-top {
  /* margin-bottom: -4px; */
  margin-bottom: -0.26rem;
}

.border-top img {
  width: 100%;
}

.border-center {
  background: url("https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/f829ed7a-baa3-4ab9-a787-abd07cc1c6c3.png")
    repeat-y;
  background-size: 100%;
  overflow-x: hidden;
}

.center-con {
  /* padding: 0px 120px; */
  padding: 0rem 0.8rem;
  box-sizing: border-box;
}

.title {
  /* font-size: 36px; */
  font-size: 0.36rem;
  color: #006aa9;
  position: relative;
  /* width: 500px; */
  width: 5rem;
  margin: 0 auto;
}

.title span {
  display: block;
  /* width: 150px;
    height: 5px; */
  width: 1.5rem;
  height: 0.05rem;
  position: absolute;
}

.title span.left {
  background: -moz-linear-gradient(left, #fff, #066eab);
  background: -webkit-linear-gradient(left, #fff, #066eab);
  /* left: -0.1rem; */
  /* margin-left: -0.1rem; */
  left: 0.9rem;
  top: 50%;
  height: 0.05rem;
  width: 2rem;
  /* margin-top: -3px; */
  margin-top: -0.03rem;
}
.wap_left {
  left: -0.1rem!important;
  width: 1rem!important;
}
.title span.right {
  background: -moz-linear-gradient(left, #066eab, #fff);
  background: -webkit-linear-gradient(left, #066eab, #fff);
  /* margin-right: 1.2rem; */
  right: 0.9rem;
  top: 50%;
  height: 0.05rem;
  width: 2rem;
  /* margin-top: -3px; */
  margin-top: -0.03rem;
}
.wap_right {
  right: -0.1rem!important;
  width: 1rem!important;
}

.logo {
  /* width: 120px;
    margin: 10px auto; */
  width: 1.2rem;
  margin: 0.01rem auto;
}

.logo img {
  width: 100%;
}

.f24 {
  /* font-size: 24px; */
  font-size: 0.24rem;
}

.c006aa9 {
  color: #006aa9;
}

.tC {
  text-align: center;
  width: 100%;
}

.mt30 {
  /* margin-top: 30px; */
  margin-top: 0.3rem !important;
}

.f26 {
  /* font-size: 26px; */
  font-size: 0.26rem;
}

.fwb {
  font-weight: bold;
}

.gongye-info {
  /* padding: 20px 25px; */
  padding: 0.2rem 0.25rem;
  border: 1px solid #eee;
  border-radius: 3px;
  color: #333;
  /* font-size: 16px; */
  font-size: 0.16rem;

  margin: 0rem auto;
}

.gongye-info p {
  /* line-height: 30px;
    padding: 5px 0; */
  line-height: 0.3rem;
  padding: 0.05rem 0rem;
}

.gongye-info p b {
  font-weight: bold;
}

.company-info {
  color: #333;
  /* font-size: 18px; */
  padding: 0 0.25rem;
  font-size: 0.18rem;
}
.link-box {
  padding: 0 0.25rem;
}
.company-info > div {
  /* line-height: 30px;
    padding: 5px 0; */
  line-height: 0.3rem;
  padding: 0.05rem 0rem;
}

.company-head {
  /* font-size: 20px; */
  font-size: 0.2rem;
  color: #006aa9;
  font-weight: bold;
}

.company-head span {
  display: inline-block;
  /* width: 25px;
    height: 25px;
    vertical-align: -5px;
    margin-right: 10px; */
  width: 0.25rem;
  height: 0.25rem;
  vertical-align: -0.05rem;
  margin-right: 0.1rem;
}

.company-head span img {
  width: 100%;
  height: 100%;
}

.c-label {
  white-space: nowrap;
  // width: 90px;
  // text-align: right;
}
.flex a {
  color: #4c9ee3;
  text-decoration: none;
  cursor: pointer;
}

.logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.25rem;
  box-sizing: border-box;
  text-align: justify;
}

.logo-box > div {
  flex: 1;
  text-align: center;
}

.logo-box > div img {
  /* width: 120px; */
  width: 1.2rem;
}

.link-ul ul {
  height: 2rem;
  margin-top: -0.8rem;
}
.link-ul h1 {
  /* font-size: 18px; */
  font-size: 0.18rem;
  font-weight: bold;
  color: #333;
  /* line-height: 40px; */
  line-height: 0.4rem !important;
  margin-bottom: 0.8rem;
}
.link-ul ul li {
  /* line-height: 40px; */
  /* line-height: 0.4rem !important; */
  height: 0.4rem;
  list-style: none;
  // margin-left: -15px;
  padding-top: 0.1rem;
}
.link-ul ul li a {
  color: #4c9ee3;
  text-decoration: none;
  font-size: 0.18rem;
  float: left;
  padding-top: 0.1rem;
  cursor: pointer;
}
.flex {
  display: flex;
  // align-items: center;
  justify-content: flex-start;
}
</style>
